import { inject, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StorageObject } from '../models/storage.interfaces';
import { AuthService } from './auth.service';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { STORAGE_OBJECT, URL_TENANT_ID, WINDOW_OBJECT } from '../core.module';
import { SentryService } from './sentry.service';
import { DataService } from './data.service';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { HttpClient } from '@angular/common/http';
import { Country } from '../../features/property-catalog/models/country.interfaces';
import { TenantStoreService } from '../stores/tenant/tenant-store.service';

export interface TenantOption {
  id: string;
  displayName: string;
}

export interface TenantConfig {
  configuration: { features: TenantConfigFeatures } | null;
  countryIsoAlpha2: string[];
  id: string;
  label: string;
}

export interface TenantConfigFeatures {
  header: HeaderFeatures;
  property: PropertyFeatures;
}

export interface HeaderFeatures {
  title: string;
  profile: string;
  calendar: string;
  deskBooking: string;
  remoteDesktop: string;
  help: string;
  search: boolean;
}

export interface PropertyFeatures {
  creatableCountries: Country[];
  certificates: boolean;
  investments: boolean;
  documents: boolean;
  pictures: boolean;
  children: boolean;
  staff: boolean;
  openDetailsTab: boolean;
  akeliusMapsUrl: string;
}

export const STORAGE_KEY_TENANT_ID = 'tenant_id';

export const TENANT_NAME_AKELIUS_RESIDENTIAL = 'akelius-residential';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TenantService extends DataService {
  URL_TENANT_ID = inject(URL_TENANT_ID) as string;
  userAllowedTenants$: BehaviorSubject<TenantOption[] | null>;
  activeTenant$: Observable<TenantOption>;
  tenantConfig$: Observable<TenantConfig>;

  constructor(
    @Inject(STORAGE_OBJECT) private storage: StorageObject,
    @Inject(WINDOW_OBJECT) private window: Window,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private authService: AuthService,
    private sentry: SentryService,
    private http: HttpClient,
    private tenantStore: TenantStoreService,
  ) {
    super();

    this.userAllowedTenants$ = new BehaviorSubject<TenantOption[] | null>(null);

    this.authService
      .getTenants$()
      .pipe(
        // map((tenants) => []),
        map((tenants) => tenants.map((tenant) => TenantService.tenantToOption(tenant))),
      )
      .subscribe({
        next: (tenants) => this.userAllowedTenants$.next(tenants),
      });

    this.activeTenant$ = this.userAllowedTenants$.pipe(
      map((allowedTenants: TenantOption[] | null) => {
        const previouslySelectedTenantId = this.URL_TENANT_ID || this.storage.localStore.getItem(STORAGE_KEY_TENANT_ID);

        return (
          allowedTenants?.find((t) => t.id === previouslySelectedTenantId) ||
          allowedTenants?.find((t) => t.id === TENANT_NAME_AKELIUS_RESIDENTIAL) ||
          allowedTenants?.[0] || { id: '', displayName: '-' }
        );
      }),
      tap((tenant) => {
        this.sentry.addTag('ak-tenant', tenant.id);
      }),
    );

    this.tenantConfig$ = combineLatest([this.activeTenant$, this.storedTenantConfig$]).pipe(
      switchMap(([tenant, storedTenantConfig]): Observable<TenantConfig> => {
        if (storedTenantConfig?.id === tenant.id) {
          return of(storedTenantConfig);
        } else {
          return this.getTenantConfig(tenant.id).pipe(
            tap((tenantConfig) => {
              this.tenantConfig = tenantConfig;
            }),
          );
        }
      }),
    );
  }

  get storedTenantConfig$(): Observable<TenantConfig | null> {
    return this.tenantStore.select<TenantConfig | null>('tenantConfig');
  }

  set tenantConfig(tenantConfig: TenantConfig) {
    this.tenantStore.set('tenantConfig', tenantConfig);
  }

  activate(tenantId: string) {
    this.activeTenant$.pipe(take(1)).subscribe({
      next: (activeTenant) => {
        if (activeTenant.id !== tenantId) {
          this.storage.localStore.setItem(STORAGE_KEY_TENANT_ID, tenantId);
          this.window.location.href = `/t/${tenantId}`;
        }
      },
    });
  }

  getTenantConfig(id: string): Observable<TenantConfig> {
    return this.http
      .get<TenantConfig>(`${this.appConfig.backend}tenant-config/${id}`)
      .pipe(catchError(this.handleError));
  }

  private static tenantToOption(tenant: string): TenantOption {
    return {
      id: tenant,
      displayName: tenant.replace('-', ' ').replace(/\b(\w{1})/g, (letter) => letter.toUpperCase()),
    };
  }
}
