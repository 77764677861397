import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-08-14T11:23:04+0000',
  },
  git: {
    branchName: 'master',
    commitHash: '8c732cad817296076c630c0601e54559aab74878',
  },
};
