import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WINDOW_OBJECT } from '../core/core.module';
import { GlobalLoadingIndicatorService } from '../core/services/global-loading-indicator.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  @HostListener('window:resize', ['$event'])
  resizeContent(_event: Event) {
    this.updateViewSize();
  }

  loading$ = this.loadingIndicatorService.loading$;

  constructor(
    @Inject(WINDOW_OBJECT) private window: Window,
    private loadingIndicatorService: GlobalLoadingIndicatorService
  ) {
    this.loadingIndicatorService.setLoading(true);
  }

  ngAfterViewInit() {
    this.updateViewSize();
  }

  private updateViewSize() {
    // window.innerHeight is an accurate representation of the viewport, taking into account address/menu bars on
    // mobile devices
    this.window.document.documentElement.style.setProperty('--app-height', `${this.window.innerHeight}px`);
  }
}
